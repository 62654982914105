import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./Header"
import Footer from "./Footer"

import "./Layout.css"

const Layout = function ({ children }) {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      settingsJson {
        navigation {
          label
          link
          subMenu {
            label
            link
          }
        }
        socials {
          facebook
          instagram
          linkedin
          twitter
          youtube
        }
        backToHome
      }
    }
  `)

  return (
    <>
      <Header
        siteTitle={data.site.siteMetadata.title}
        settingsJson={data.settingsJson}
      />
      <main>{children}</main>
      <Footer siteTitle={data.site.siteMetadata.title} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
