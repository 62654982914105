import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { v4 as uuidv4 } from "uuid"
import ReactAudioPlayer from "react-audio-player"

import { container, nav, ul, link, dropdown } from "./Header.module.css"

const Dropdown = function ({ menuItem }) {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <button
        className={link}
        type="button"
        onClick={(_) => setIsOpen((value) => !value)}
      >
        {menuItem.label}
      </button>
      <ul className={`${dropdown} ${isOpen ? `block` : `hidden`}`}>
        {menuItem.subMenu.map(function (subMenu) {
          return (
            <li key={uuidv4()}>
              <Link className={link} activeClassName="active" to={subMenu.link}>
                {subMenu.label}
              </Link>
            </li>
          )
        })}
      </ul>
    </>
  )
}

Dropdown.propTypes = {
  menuItem: PropTypes.shape({
    label: PropTypes.string.isRequired,
    subMenu: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
}

const Header = function ({ siteTitle, settingsJson }) {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <header className="bg-primary sm:flex sm:justify-between sm:items-center py-1.5 md:py-2.5">
      <div className={`container ${container}`}>
        <Link to="/" title={settingsJson.backToHome}>
          <StaticImage src="../images/logo.png" alt={`Logo ${siteTitle}`} />
        </Link>

        <div className="flex md:order-2">
          <ReactAudioPlayer
            src="https://stream.onestation.fr/128.mp3"
            controls
          />
          <button
            className={`button ml-4 md:hidden hover:text-white ${
              isOpen && `text-white`
            }`}
            onClick={(_) => setIsOpen((state) => !state)}
            type="button"
            aria-label="Toggle navigation"
          >
            <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
              {isOpen ? (
                <path
                  fillRule="evenodd"
                  d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
                />
              ) : (
                <path
                  fillRule="evenodd"
                  d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                />
              )}
            </svg>
          </button>
        </div>

        <nav className={`${nav} ${isOpen ? `block` : `hidden`} md:block`}>
          <ul className={ul}>
            {settingsJson.navigation.map(function (menuItem) {
              if (menuItem.subMenu !== null) {
                return (
                  <li className="relative" key={uuidv4()}>
                    <Dropdown menuItem={menuItem} />
                  </li>
                )
              }
              return (
                <li key={uuidv4()}>
                  <Link
                    className={link}
                    activeClassName="active"
                    to={menuItem.link}
                  >
                    {menuItem.label}
                  </Link>
                </li>
              )
            })}
          </ul>
        </nav>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string.isRequired,
  settingsJson: PropTypes.oneOfType([PropTypes.object]).isRequired,
}

export default Header
