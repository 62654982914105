import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const Footer = function ({ siteTitle }) {
  return (
    <footer>
      <div className="container text-center">
        ©{` `}
        {new Date().getFullYear()}
        {` `}
        <Link className="text-inherit hover:text-primary" to="/">
          {siteTitle}
        </Link>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string.isRequired,
}

export default Footer
